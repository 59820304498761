import React from "react"
import ForgotPasswordForm from "../components/auth/forgot-password-form"
import FullPageLayout from "../components/full-page-layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

function ForgotPasswordPage() {
  const { allPrismicLoginPage } = useStaticQuery(graphql`
    query ForgotPasswordQuery {
      allPrismicLoginPage {
        edges {
          node {
            data {
              right_image {
                alt
                url
              }
              seo_keywords_forgot_password {
                html
                text
              }
              seo_title_forgot_password {
                html
                text
              }
              seo_description_forgot_password {
                html
                text
              }
            }
          }
        }
      }
    }
  `)

  const {
    right_image,
    seo_keywords_forgot_password,
    seo_title_forgot_password,
    seo_description_forgot_password,
  } = allPrismicLoginPage.edges[0].node.data

  return (
    <div>
      <SEO
        keywords={[
          seo_keywords_forgot_password?.text ??
            seo_keywords_forgot_password.text.split(","),
        ]}
        title={seo_title_forgot_password?.text}
        description={seo_description_forgot_password?.text}
        excludeSiteMetadataTitle
      />
      <FullPageLayout rightImageUrl={right_image.url}>
        <div className="flex items-center pt-32 lg:pt-36">
          <div className="text-center w-full px-6 lg:px-16">
            <div className="w-full flex items-center">
              <div className="w-full">
                <div>
                  <h1 className="headline-text">Forgot Password</h1>
                  <div className="pt-8 text-center">
                    <h2 className="sub-headline-text mx-8">
                      {seo_description_forgot_password?.text}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto md:w-2/3">
              <ForgotPasswordForm />
            </div>
          </div>
        </div>
      </FullPageLayout>
    </div>
  )
}

export default ForgotPasswordPage
