import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { getValidationErrors } from "../../utils/graphql-error-handler"
import { navigate } from "gatsby"
import { MUTATION_FORGOT_PASSWORD } from "../../graphql/mutations/auth"
import { useAuth } from "../../context/auth-context"

function ForgotPasswordForm() {
  const { logout } = useAuth()

  const [forgotPassword, { loading }] = useMutation(MUTATION_FORGOT_PASSWORD)

  const [email, setEmail] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const handleChange = ({ target }) => {
    setEmail(target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (showSuccess) {
      navigate("/login")
      return
    }

    forgotPassword({ variables: { email } })
      .then(({ data }) => {
        if (data.forgotPassword.status === "EMAIL_SENT") {
          setShowSuccess(true)
          setErrorMessages([])
          if (typeof logout === "function") {
            logout()
          }
        } else {
          throw new Error(data.forgotPassword.message)
        }
      })
      .catch((error) => {
        setShowSuccess(false)
        const messages = getValidationErrors(error)

        if (messages.length > 0) {
          setErrorMessages(messages)
        } else if (error.message) {
          setErrorMessages([error.message])
        } else {
          setErrorMessages([
            "Unable to reset your account password. Please try again!",
          ])
        }
      })
  }

  return (
    <form onSubmit={handleSubmit} className="lg:pb-8">
      <div className="w-full">
        {showSuccess && (
          <div className="text-primary font-bold mt-6 text-center" role="alert">
            We have e-mailed your password reset link!
          </div>
        )}
        <label className="block md:w-1/2 pr-3 mt-4 mb-2" htmlFor="subject">
          <span className="font-bold" />
          <p />
        </label>
        <input
          onChange={handleChange}
          className="appearance-none bg-transparent border-b w-full mr-3 py-1 px-2 leading-tight focus:outline-none"
          id="email"
          name="email"
          data-testid="email"
          type="email"
          placeholder="Email Address"
          aria-label="email"
        />
        {errorMessages.length > 0 &&
          errorMessages.map((error) => (
            <div key={error} role="alert">
              <small className="text-error">{error}</small>
            </div>
          ))}
      </div>
      <div className="py-4 mx-auto">
        <input
          disabled={loading}
          className="btn in-app -primary -space-above disabled"
          type="submit"
          data-testid="submit"
          value={
            showSuccess
              ? "Back to Login"
              : loading
              ? "Resetting Password..."
              : "Submit"
          }
        />
      </div>
    </form>
  )
}

export default ForgotPasswordForm
